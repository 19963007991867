import { device } from 'src/styles/breakpoints'
import * as newColors from 'src/styles/newColors'
import styled from 'styled-components'

export const AntecipacaoFgtsSection = styled.section`
  background-color: ${newColors.orange.dark};
  padding: 64px 0 54px 0;

  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: center;
  background-size: auto 100%;
  
  @media ${device.tablet} {   
    padding: 98px 0 70px 0;
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/banner-antecipacao-fgts-whatsapp-md/image.webp);    
  }

  @media ${device.desktopLG} {
    padding: 126px 0;
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/banner-antecipacao-fgts-whatsapp-lg/image.webp);
  }

  @media ${device.desktopXXL} {
    padding: 110px 0 94px 0;
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/banner-antecipacao-fgts-whatsapp-xl/image.webp);
  }
`

export const Title = styled.h1`
  font-family: "Citrina", Helvetica, sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  text-align: right;
  color: white;
  
  margin-bottom: 16px;
  width: 85%;

  @media ${device.tablet} {
    font-size: 28px;
    line-height: 34px;
  }

  @media ${device.desktopLG} {
    font-size: 40px;
    line-height: 44px;

    width: 95%;
  }

  @media ${device.desktopXXL} {
    font-size: 48px;
    line-height: 53px;
  }
`

export const Description = styled.p`
font-family: "Inter", Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: white;
  letter-spacing: 0px;

  margin-bottom: 32px;

  @media ${device.tablet} {
    width: 95%;
    letter-spacing: inherit;
  }

  @media ${device.desktopLG} {
    font-size: 16px;
    line-height: 19px;
   
    width: 97%;
  }

  @media ${device.desktopXXL} {
    font-size: 20px;
    line-height: 25px;

    margin-bottom: 40px;

    width: 80%;
  }
`

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0;
  .whatsapp_simulation{
    &__icon {
      width: 24px;
      height: 24px;

      margin-left: 8px;
    }
  }

`

export const Row = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row-reverse;
  }

  .section_image {
    margin-bottom: 40px;
  }
`
